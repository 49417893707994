<template>
  <el-dialog
    title=""
    :visible.sync="regulationShow"
    width="60%"
    :show-close="false"
  >
    <div class="regulationShow">
      <div class="regulationShow-title">
        <img
          src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
          alt=""
          class="regulationShow-x"
          @click="regulationShow = false"
        />规则
      </div>
      <div class="regulationShow-text">
        <div>抽奖规则</div>
        <div class="regulationShow-text-top">一、抽奖资格</div>
        <div>1.参与者需完成注册实名并登录。</div>
        <div class="regulationShow-text-top">二、获得抽奖次数的方式</div>
        <div>（一）完成交互类任务</div>
        <div>
          参与者每完成一项特定的交互类任务，如订阅官方自媒体、分享活动页面至社交媒体等，可获得
          1 次抽奖机会。
        </div>
        <div>（二）邀请他人注册实名</div>
        <div>
          参与者每成功邀请两位新用户注册并完成实名，可获得 1
          次抽奖机会。邀请次数上限为 50人，总计获得抽奖机会上限为 25 次。
        </div>
        <div>（三）每日签到</div>
        <div>参与者每日签到可获得 1 次抽奖机会</div>
        <div class="regulationShow-text-top">三、其他说明</div>
        <div>
          1.抽奖次数仅限在本次活动期间内使用，活动结束后未使用的抽奖次数将自动作废。
        </div>
        <div>2.严禁任何作弊行为，一经发现，将取消抽奖资格。</div>
        <div>3.活动最终解释权归主办方所有。</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      regulationShow: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.regulationShow {
  width: 1186px;
  height: 815px;
  background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 88px 114px 103.34px 158px;
  &-x {
    cursor: pointer;
  }
  &-x {
    position: absolute;
    top: 63.96px;
    right: 36.76px;
    width: 80.88px;
    height: 79.56px;
  }
  &-title {
    width: 100%;
    color: #574352;
    font-size: 48px;
    font-weight: 900;
    text-align: center;
  }
  &-text::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-text {
    flex: 1;
    overflow: auto;
    margin-top: 40px;
    color: #574352;
    font-size: 24px;
    line-height: 40.8px;
    // background: #d6c4ba;
    padding: 45px 50px;
    &-top {
      margin-top: 30px;
    }
  }
}
</style>