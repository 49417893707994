<template>
  <div class="dailyattendance">
    <div class="dailyattendance-bgc">
      <div class="dailyattendance-bgc-rankingList">
        <div class="dailyattendance-bgc-rankingList-bgc">
          <img
            src="https://cdn.bubbleplan.cn/static/drawalotteryorraffle/371.png"
            alt=""
            class="dailyattendance-bgc-rankingList-bgc-img"
            @click="(rankingListShow = true), rankList()"
          />
          <img
            src="https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9821.png"
            alt=""
            class="dailyattendance-bgc-rankingList-bgc-img1"
            @click="regulationShow()"
          />
          <div class="dailyattendance-bgc-rankingList-bgc-box">
            <div
              v-for="(item, index) in userListWu"
              :key="index"
              class="dailyattendance-bgc-rankingList-bgc-box-li flex"
            >
              <div class="dailyattendance-bgc-rankingList-bgc-box-li-text">
                {{ item.name }}
              </div>
              <div class="dailyattendance-bgc-rankingList-bgc-box-li-text">
                <span style="color: #daff00">{{ item.integration }}</span>
                积分
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dailyattendance-bgc-button">
        <img
          src="https://cdn.bubbleplan.cn/static/drawalotteryorraffle/myinvitation.png"
          alt=""
          class="dailyattendance-bgc-button-myinvitation"
          @click="myInvita()"
        />
        <img
          src="https://cdn.bubbleplan.cn/static/drawalotteryorraffle/acquisitionTimes.png"
          alt=""
          class="dailyattendance-bgc-button-acquisitionTimes"
          @click="acquire()"
        />
        <img
          src="https://cdn.bubbleplan.cn/static/drawalotteryorraffle/drawRecord.png"
          alt=""
          class="dailyattendance-bgc-button-drawRecord"
          @click="fetchrecord()"
        />
      </div>

      <div class="dailyattendance-bgc-turntable">
        <div class="lucky-draw">
          <div class="lucky-draw__objects">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/turntable.png"
              class="lucky-draw__objects__bg"
            />
            <div
              class="lucky-draw__objects__object"
              v-for="(item, index) in prizeList"
              :key="index"
              :style="{
                transform: `translate(-50%) rotate(${(360 / 8) * index}deg)`,
              }"
            >
              <div
                class="lucky-draw__objects__object__title"
                :style="nowaday == item.id ? ' color: #816400;' : ''"
              >
                {{ item.name }}
              </div>
              <img class="lucky-draw__objects__object__icon" :src="item.img" />
            </div>
            <img
              class="lucky-draw__start"
              src="https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/theCircle.png"
              @click="starta()"
              :style="rotateStyle"
            />
          </div>
        </div>
        <div class="flex" style="justify-content: center">
          <div class="dailyattendance-bgc-turntable-but">
            可抽奖次数：<span style="color: rgb(242, 255, 0)">{{
              numberofdraws
            }}</span
            >次
          </div>
        </div>
        <el-dialog
          title=""
          :visible.sync="rankingListShow"
          width="30%"
          :show-close="false"
        >
          <div class="page-content">
            <img
              src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
              alt=""
              class="page-content-x"
              @click="rankingListShow = false"
            />
            <div class="page-content-type flex flex-around">
              <div
                v-for="(item, index) in typeList"
                :key="index"
                class="page-content-type-li"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="page-content-ul">
              <div
                v-for="(item, index) in userList.rankVo"
                :key="index"
                :class="
                  index == 0
                    ? 'page-content-ul-li1'
                    : index == 1
                    ? 'page-content-ul-li2'
                    : index == 2
                    ? 'page-content-ul-li3'
                    : 'page-content-ul-li'
                "
                style="margin-bottom: 6px"
                class="page-content-ul-box flex items-center flex-between"
              >
                <div style="width: 23%">
                  <img
                    :src="
                      index == 0
                        ? 'http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9832.png'
                        : index == 1
                        ? 'https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9833.png'
                        : index == 2
                        ? 'https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9834.png'
                        : ''
                    "
                    mode=""
                    v-if="index < 3"
                    :class="
                      index == 0
                        ? 'page-content-ul-box-img'
                        : index == 1
                        ? 'page-content-ul-box-img1'
                        : 'page-content-ul-box-img2'
                    "
                  />

                  <div v-else class="page-content-ul-box-num">
                    {{ index + 1 }}
                  </div>
                </div>
                <div class="page-content-ul-box-name">{{ item.name }}</div>
                <div class="page-content-ul-box-integral">
                  {{ item.integration }}
                </div>
              </div>
            </div>
            <div class="page-content-my flex items-center flex-between">
              <div class="sort-sort" style="">
                {{ userList.sort + 1 }}
              </div>
              <div class="page-content-my-num" style="margin-left: 50px">
                {{ userList.name }}
              </div>
              <div class="page-content-my-num">{{ userList.integration }}</div>
            </div>
          </div>
        </el-dialog>
        <regulation ref="regulationRef"></regulation>
        <drawRecord
          :drawRecordList="drawRecordList"
          @drawLog="drawLog"
          ref="drawRecordRef"
        ></drawRecord>
        <myInvitation
          ref="myInvitationRef"
          :subordinateList="subordinateList"
          x
          @mySubordinates="mySubordinates"
        ></myInvitation>
        <numberofdraws
          ref="numberofdrawsRef"
          :taskList="taskList"
          :nameSubordinate="nameSubordinate"
          :isSignin="isSignin"
        ></numberofdraws>
      </div>
    </div>
  </div>
</template>
<script>
import regulation from "./components/regulation.vue";
import drawRecord from "./components/drawRecord.vue";
import myInvitation from "./components/myInvitation.vue";
import numberofdraws from "./components/numberofdraws.vue";
export default {
  components: {
    regulation,
    drawRecord,
    myInvitation,
    numberofdraws,
  },
  data() {
    return {
      rankingListShow: false,
      prizeList: [],
      cycle: 2,
      circle: 1,
      duration: 5000,
      mode: "cubic-bezier(0.25, 0.1, 0.25, 1)",
      rotateAngle: 0,
      isStart: true,
      typeList: [
        {
          name: "排名",
        },
        {
          name: "昵称",
        },
        {
          name: "积分",
        },
      ],
      userList: [],
      userListWu: [],
      numberofdraws: 0,
      nowaday: 1,
      drawRecordList: [],
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      subordinateList: [],
      taskList: [],
      nameSubordinate: [],
      isSignin: null,
    };
  },
  computed: {
    rotateStyle() {
      return `
		        -webkit-transition: transform ${this.duration}ms ${this.mode};
		        transition: transform ${this.duration}ms ${this.mode};
		        -webkit-transform: rotate(${this.rotateAngle}deg);
		            transform: rotate(${this.rotateAngle}deg);`;
    },
  },
  mounted() {
    this.allPrize();
    this.getOwnerNumByType();
    this.rankList();
    this.signIn();
  },
  methods: {
    //获取任务
    async listTask() {
      const { data, code } = await this.$ajax.listTask();
      this.taskList = data;
    },
    //获取签到
    async signIn() {
      const { data, code } = await this.$ajax.isSign();
      this.isSignin = data;
    },
    acquire() {
      this.signIn();
      this.mySubordinatess();
      this.listTask();
      this.$refs.numberofdrawsRef.numberofdrawsShow = true;
    },
    myInvita() {
      this.page.pageNum = 1;
      this.mySubordinates();
      this.$refs.myInvitationRef.myInvitationShow = true;
    },
    //查看下级
    async mySubordinates(type) {
      if (type == "bottom") {
        this.page.pageNum++;
      }
      const params = {
        ...this.page,
      };
      const { data } = await this.$ajax.mySubordinates(params);
      if (type == "bottom") {
        this.subordinateList.push(...data.list);
      } else {
        this.subordinateList = data.list;
      }
    },
    //查看实名下级
    async mySubordinatess(type) {
      const params = {
        pageNum: 1,
        pageSize: 50,
        isSign: 1,
      };
      const { data } = await this.$ajax.mySubordinates(params);
      this.nameSubordinate = data.list;
    },
    fetchrecord() {
      this.$refs.drawRecordRef.drawRecordShow = true;
      this.page.pageNum = 1;
      this.drawLog();
    },
    //获取抽奖记录
    async drawLog(type) {
      if (type == "bottom") {
        this.page.pageNum++;
      }
      const params = {
        ...this.page,
      };
      const { data, code } = await this.$ajax.drawLog(params);
      if (type == "bottom") {
        this.drawRecordList.push(...data.list);
      } else {
        this.drawRecordList = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },

    regulationShow() {
      this.$refs.regulationRef.regulationShow = true;
    },
    //抽奖
    async starta() {
      if (this.isStart) {
        this.nowaday = null;
        //随机数不超过转盘奖励数量
        const { data, code } = await this.$ajax.doDraw({
          count: 1,
        });
        this.getOwnerNumByType();
        if (code == 200) {
          this.drawId = data[0];

          console.log(this.drawId);
          this.isStart = false;
          this.rotateAngle =
            this.circle * 360 * this.cycle +
            this.prizeList.findIndex((item) => item.id == this.drawId) *
              (360 / 8);
          if (this.drawId < 5) {
            this.rotateAngle -= 1;
          } else if (this.drawId < 6) {
            this.rotateAngle += 2;
          } else {
            this.rotateAngle += 3;
          }
          this.cycle++;
          this.cycle++;
          setTimeout(() => {
            this.prizeList.forEach((item) => {
              if (item.id == this.drawId) {
                // uni.showToast({
                //   title: "恭喜您获得" + item.name,
                //   icon: "none",
                // });

                this.$message({
                  message: "恭喜您获得" + item.name,
                  type: "success",
                });
                this.isStart = true;
              }
            });
            this.nowaday = this.drawId;
          }, 5000);
        } else {
          this.isStart = true;
        }
      }
    },
    //积分排行榜
    async rankList() {
      this.userListWu = [];
      const { data, code } = await this.$ajax.rankList();
      data.rankVo.forEach((item, index) => {
        if (index < 4) {
          this.userListWu.push(item);
        }
      });
      this.userList = data;
    },
    //转盘所有奖项
    async allPrize() {
      const { data, code } = await this.$ajax.allPrize();
      this.prizeList = data;
    },
    //获取次数
    async getOwnerNumByType() {
      const { data, code } = await this.$ajax.getOwnerNumByType({
        type: 2,
      });
      this.numberofdraws = Math.floor(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.dailyattendance::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.dailyattendance {
  height: calc(100vh - 94px);
  width: 100%;
  overflow: auto;
  &-bgc {
    width: 100%;
    height: 1080px;
    background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/bgc.png")
      no-repeat;
    background-size: 100% 100%;
    margin-top: -5px;
    position: relative;
    &-rankingList {
      position: absolute;
      top: 101px;
      left: 50px;

      &-bgc {
        width: 270px;
        height: 301px;
        flex-shrink: 0;
        border-radius: 10px;
        background: linear-gradient(180deg, #000 -25.58%, #00000000 100%);
        position: relative;
        &-img:hover {
          cursor: pointer;
        }
        &-img {
          width: 133px;
          height: 108px;
          position: absolute;
          top: -67px;
          left: 68px;
        }
        &-img1:hover {
          cursor: pointer;
        }
        &-img1 {
          width: 56px;
          height: 56px;
          position: absolute;
          right: -80px;
          top: -28.5px;
        }
        &-box {
          padding: 56px 30px 0;
          &-li {
            margin-bottom: 9px;
            justify-content: space-between;
            &-text {
              color: #ffffff;
              font-size: 16px;
              line-height: 37.8px;
            }
          }
          &-li:nth-child(5) {
            opacity: 0.3;
          }
          &-li:nth-child(4) {
            opacity: 0.5;
          }
          &-li:nth-child(3) {
            opacity: 0.7;
          }
        }
      }
    }
    &-button {
      position: absolute;
      right: 0;
      top: 302.75px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &-myinvitation:hover {
        cursor: pointer;
      }
      &-drawRecord:hover {
        cursor: pointer;
      }
      &-acquisitionTimes:hover {
        cursor: pointer;
      }
      &-myinvitation {
        width: 218px;
        height: 84px;
      }
      &-acquisitionTimes {
        width: 287px;
        height: 84px;
        margin-top: 30px;
      }
      &-drawRecord {
        width: 218px;
        height: 84px;
        margin-top: 30px;
      }
    }
    &-turntable {
      padding-top: 151px;
      .lucky-draw {
        position: relative;
        width: fit-content;
        margin: auto;

        &__lights {
          position: absolute !important;
          width: 676px;
          left: 6px;
          top: 10px;
          display: block;
          animation: infinite 1s alternate lights steps(1, end);
        }

        &__objects {
          // position: absolute;
          top: -40px;
          left: 20px;
          right: 63px;
          bottom: 78px;
          width: 700px;
          height: 700px;

          &__bg {
            position: absolute;
            width: 700px;
            height: 700px;
          }

          &__object {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 100px;
            transform-origin: 50% 250px;
            z-index: 99;

            &__title {
              margin-top: -50px;
              margin-left: 8px;
              color: #ffffff;

              font-size: 26px;
              font-weight: 900;
              // -webkit-transform: scale(.8);
            }

            &__icon {
              width: 100px;
              height: 100px;
              display: block;
              margin: 28px auto 0;
            }
          }
        }

        &__start {
          width: 700px;
          height: 700px;
          display: block;
          position: absolute;
          // left: 10rpx;
          left: 0;
          top: 0px;
        }
      }

      @keyframes lights {
        0% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(15deg);
        }

        100% {
          transform: rotate(0deg);
        }
      }
      &-but {
        width: 378px;
        height: 107px;
        background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/bbb_bgc.png")
          no-repeat;
        background-size: 100% 100%;
        color: rgb(255, 255, 255);
        font-family: "qiantuhouheiti";
        line-height: 107px;
        text-align: center;
        font-size: 36px;
      }
    }
    :deep(.el-dialog__wrapper)::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    :deep(.el-dialog__header) {
      padding: 0 !important;
    }
    :deep(.el-dialog__body) {
      padding: 0 !important;
    }
    :deep(.el-dialog) {
      //   width: 676px !important;
      background: transparent !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .page-content {
      width: 676px;
      height: 921px;
      background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9857.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      &-x {
        cursor: pointer;
      }
      &-x {
        position: absolute;
        right: -25.12px;
        top: 82px;
        width: 69.12px;
        height: 68px;
      }
      &-type {
        padding: 0 42px;
        padding-top: 204px;
        color: #87697f;
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 19px;

        &-li:nth-child(1) {
          color: #7036ec;
          width: 20%;
        }

        &-li {
          width: 35%;
          text-align: center;
        }
      }
      &-ul::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &-ul {
        flex: 1;
        padding: 0 25px;
        overflow: auto;

        &-li1 {
          background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9837.png")
            no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 95px;
        }

        &-li2 {
          background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9858.png")
            no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 95px;
        }

        &-li3 {
          background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9859.png")
            no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 95px;
        }

        &-li {
          background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9860.png")
            no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 95px;
        }

        &-box {
          padding: 0 41px;

          &-img {
            width: 95px;
            height: 110px;
            margin-left: -12px;
            margin-top: -22px;
          }

          &-img1 {
            width: 80px;
            height: 80px;
          }

          &-img2 {
            width: 80px;
            height: 80px;
          }

          &-num {
            // width: 20%;
            height: 81px;
            text-align: center;
            color: #fcf5ee;
            font-size: 60px;
            font-family: "qiantuhouheiti";
          }

          &-name {
            color: #2f2f2f;
            text-align: center;
            font-size: 24px;
            font-family: "Source Han Sans SC";
            font-weight: 900;
            width: 37%;
          }

          &-integral {
            width: 35%;
            color: #2f2f2f;
            text-align: center;
            font-family: "Source Han Sans SC";
            font-size: 24px;
            font-weight: 900;
          }
        }
      }
      &-my {
        width: 100%;
        background: url("https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9838.png")
          no-repeat;
        background-size: 100% 100%;
        height: 92.93px;
        width: 621.83px;
        margin: auto;
        margin-bottom: 20px;
        padding: 0 21px;
        padding-bottom: 16px;

        &-num {
          color: #ffffff;
          width: 35%;
          text-align: center;
          font-size: 24px;
          font-weight: 900;
          font-family: "Source Han Sans SC";
        }
      }
    }
    .sort-sort {
      width: 21%;
      color: #fcf5ee;
      text-align: center;
      font-size: 60px;
      font-family: "qiantuhouheiti";
    }
  }
}
</style>