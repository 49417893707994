<template>
  <el-dialog
    title=""
    :visible.sync="myInvitationShow"
    width="40%"
    :show-close="false"
  >
    <div class="myInvitation">
      <div class="myInvitation-title">
        <img
          src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
          alt=""
          class="myInvitation-x"
          @click="myInvitationShow = false"
        />我的邀请
      </div>
      <div class="myInvitation-text">
        <div class="myInvitation-text-type flex">
          <div class="myInvitation-text-type-text">用户UID</div>
        </div>
        <div class="myInvitation-text-list" @scroll="handleScroll">
          <div
            v-for="(item, index) in subordinateList"
            :key="index"
            class="myInvitation-text-list-box flex"
          >
            <div class="myInvitation-text-list-box-text" style="width: 60%">
              {{ item.invitationUserId }}
            </div>
            <div class="myInvitation-text-list-box-bu" v-if="item.isSign == 1">
              已实名
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
    <script>
export default {
  props: {
    subordinateList: Array,
  },
  data() {
    return {
      myInvitationShow: false,
    };
  },
  methods: {
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("mySubordinates", "bottom");
      }
    },
  },
};
</script>
    
<style lang="scss" scoped>
.myInvitation {
  width: 936.32px;
  height: 792.77px;
  background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 88px 114px 103.34px 158px;
  &-x {
    cursor: pointer;
  }
  &-x {
    position: absolute;
    top: 63.96px;
    right: 36.76px;
    width: 80.88px;
    height: 79.56px;
  }
  &-title {
    width: 100%;
    color: #574352;
    font-size: 48px;
    font-weight: 900;
    text-align: center;
  }
  &-text::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-text {
    flex: 1;
    overflow: auto;
    margin-top: 40px;
    color: #574352;
    font-size: 24px;
    line-height: 40.8px;
    // background: #d6c4ba;
    padding: 45px 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-type {
      width: 100%;
      &-text {
        width: 100%;
        text-align: center;
        color: #574352;
        font-size: 28px;
      }
    }
    &-list::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-list {
      flex: 1;
      width: 100%;
      overflow: auto;
      margin-top: 20px;
      &-box {
        margin-bottom: 24px;
        width: 100%;
        &-text {
          color: #574352;
          text-align: center;
          width: 50%;
          text-align: center;
          font-weight: 500;
          font-size: 26px;
        }
        &-bu {
          width: 101px;
          height: 39px;
          border-radius: 180px;
          background: #5f4e81;
          color: #f5efe2;
          text-align: center;
          font-size: 23px;
          line-height: 39px;
        }
      }
    }
  }
}
</style>