<template>
  <el-dialog
    title=""
    :visible.sync="drawRecordShow"
    width="60%"
    :show-close="false"
  >
    <div class="drawRecord">
      <div class="drawRecord-title">
        <img
          src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
          alt=""
          class="drawRecord-x"
          @click="drawRecordShow = false"
        />抽奖记录
      </div>
      <div class="drawRecord-text">
        <div class="drawRecord-text-type flex">
          <div class="drawRecord-text-type-text">日期</div>
          <div class="drawRecord-text-type-text">奖品</div>
        </div>
        <div class="drawRecord-text-list" @scroll="handleScroll">
          <div
            v-for="(item, index) in drawRecordList"
            :key="index"
            class="drawRecord-text-list-box flex"
          >
            <div class="drawRecord-text-list-box-text">
              {{ item.updateTime }}
            </div>
            <div class="drawRecord-text-list-box-text">
              {{ item.prizeName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  <script>
export default {
  props: {
    drawRecordList: Array,
  },
  data() {
    return {
      drawRecordShow: false,
    };
  },
  methods: {
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("drawLog", "bottom");
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.drawRecord {
  width: 1186px;
  height: 815px;
  background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 88px 114px 103.34px 158px;
  &-x {
    cursor: pointer;
  }
  &-x {
    position: absolute;
    top: 63.96px;
    right: 36.76px;
    width: 80.88px;
    height: 79.56px;
  }
  &-title {
    width: 100%;
    color: #574352;
    font-size: 48px;
    font-weight: 900;
    text-align: center;
  }
  &-text::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-text {
    flex: 1;
    overflow: auto;
    margin-top: 40px;
    color: #574352;
    font-size: 24px;
    line-height: 40.8px;
    // background: #d6c4ba;
    padding: 45px 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-type {
      width: 100%;
      &-text {
        width: 50%;
        text-align: center;
        color: #574352;
        font-size: 28px;
      }
    }
    &-list::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-list {
      flex: 1;
      width: 100%;
      overflow: auto;
      margin-top: 20px;
      &-box {
        margin-bottom: 24px;
        width: 100%;
        &-text {
          color: #574352;
          text-align: center;
          width: 50%;
          text-align: center;
          font-weight: 500;
          font-size: 26px;
        }
      }
    }
  }
}
</style>